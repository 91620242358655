import React, { useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex, Text } from '../components/primitives'
import { conceptPrice, guidancePrice } from '../helpers/config'

const mb = 5
const fontSize = 3
export default ({ navigate }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      headTitle={'Vanliga frågor & svar om inredningshjälp online | Hemset.se'}>
      <Flex jusifyContent={'center'}>
        <Flex
          mt={['80px', '80px', '80px']}
          maxWidth={'1000px'}
          minHeight={'100vh'}
          p={4}
          flexDirection={'column'}>
          <Text fontWeight={'bold'} fontSize={8} mb={4}>
            FAQ Hemset
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Är det några avgifter som tillkommer?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Tjänsten kostar {conceptPrice} per rum för ett koncept och{' '}
            {guidancePrice} per rum för rådgivning. Utöver det kan det tillkomma
            fraktkostnader för produkterna som beställs hem.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Har jag personlig kontakt med en inredare?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Ja, du kommer att kommunicera med inredaren på det sätt som du
            känner dig mest bekväm med, oavsett om det sker via e-post, telefon,
            facetime eller sms.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Hur kommer inredaren att veta vad jag gillar?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Som en del av processen fyller du i en stilguide som ger din
            interedare din stilprofil. Du kan också skicka bilder av rum som du
            tycker är inspirerande.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Beställer jag allting själv?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Ja precis, det är du själv som lägger beställningen på
            produkterna. För dig blir det enkelt att dela upp beställningen om
            så önskas.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Kan budgeten som sätts vara flexibel?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Den kan vara flexibel om du så önskar. Det finns möjlighet att
            välja flexibel när du sätter din budget.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Använder ni även mina befintliga möbler i inredningskonceptet, eller
            är det endast nya produkter?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Har du möbler du vill ha kvar så anpassar inredaren ditt koncept
            efter det och hjälper dig att matcha de nya produkter som
            tillkommer.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Hjälper inredaren även till med färgsättning på väggarna också?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Ja, inredarna är vana vid att arbeta med övergripande koncept, så
            om du ber om färgförslag kommer det att inkluderas i det slutliga
            förslaget.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Hur får inredaren en känsla av storleken på rummet?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Det beror på hur ditt rum ser ut och dina behov. Om inredaren är i
            behov av mått kommer du att få en förfrågan av det med tips på hur
            du mäter.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Vilka betalsätt är möjliga?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Vi använder oss av Klarna där både faktura och kortbetalning är
            möjligt.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Hur sker betalningen?
          </Text>
          <Text mb={mb} fontSize={fontSize}>
            - Vi använder oss av en lösning från Reve AB. Betalningen går direkt
            genom Klarna till leverantörerna. Varken Reve AB eller Hemset AB tar
            emot din betalning. Du lägger med andra ord din beställning direkt
            hos leverantören.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Vad innehåller ett inredningskoncept?
          </Text>
          <Text mb={2} fontSize={fontSize}>
            - Ett inredningskoncept innehåller: <br />
            <br />
            <Flex ml={2} mb={mb} fontSize={fontSize}>
              • Professionell inredningshjälp för ett rum. <br />
              • En moodboard anpassat för dig och din stil. <br />
              • En planskiss över ditt rum. <br />
              • En digital shoppinglista baserat på dina behov och önskemål,
              vilket gör att du enkelt kan beställa hem artiklarna. <br />•
              Vidare diskussioner kring rummet och befintliga möblers
              förutsättningar och möjligheter.
            </Flex>
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Är det möjligt att ändra eller lägga till något i konceptet om det
            inte faller sig i smaken?
          </Text>
          <Text mb={2} fontSize={fontSize}>
            - Inte riktigt din stil? Nemas problemas, vår inredare förbättrar
            konceptet en vända.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={5}>
            Nöjd Kund - garanti
          </Text>
          <Text mb={2} fontSize={fontSize}>
            - Vi gör alltid vårt absolut yttersta för att du som kund ska bli
            nöjd. Men ibland kan det gå galet. Om du inte tycker att du fått den
            servicen du förtjänar så ser vi till att du får ett nytt koncept. Är
            du fortfarande inte nöjd får du pengarna tillbaka.
          </Text>
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
